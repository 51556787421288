@import "src/styles/media";

@keyframes modalIn {
  0% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes modalOut {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }
}

@keyframes helpFadeIn {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes helpCloseIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes wrapOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow-y: auto;
  overflow-x: hidden;
  //padding: 40px 0;

  &_closing {
    background: rgba(#000, 0);

    .modal__content {
      animation: modalOut .3s ease-in forwards;
      animation-iteration-count: 1;
    }
  }

  &_loading {
    .modal__actions {
      .button {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &_task {
    .modal__content {
      background: #fff;

      h2, p, ul {
        text-shadow: none;
        color: #333;
      }

      h2 {
        color: var(--titleColor);
      }

      .modal__corner_rt {
        display: none;
        visibility: hidden;
      }
    }

    .modal__task-points {
      width: 161px;
      height: 69px;
      background: url("./images/task-mercoin.png") 50% no-repeat;
      background-size: 100%;
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);

      @include small-desktop-view() {
        width: 215px;
        height: 88px;
        top: -8px;
      }

      span {
        position: absolute;
        text-align: right;
        width: 50px;
        left: 50%;
        font-size: 26px;
        top: 11px;
        margin-left: -24px;
        transform: translateX(-50%);
        line-height: 1;
        color: var(--lightColor);
        text-shadow: 0 2px 2px var(--shadowDarkColor);

        @include small-desktop-view() {
          width: 60px;
          left: 50%;
          font-size: 35px;
          top: 14px;
          margin-left: -30px;
        }
      }
    }
  }

  &_wide {
    overflow-y: auto;

    .modal__content {
      z-index: 100001;
      animation: helpFadeIn .5s;
      text-align: left;
      left: 0;
      top: 0;
      transform: none !important;
      position: relative;
      width: 100%;
      background: var(--lightColor);
      margin: 0 auto;
      max-width: none;

      @include small-desktop-view() {
      }

      @include large-desktop-view() {
        margin: 40px auto;
        max-width: 800px;
      }

      .modal__corner_rt {
        visibility: visible;
        display: block;
      }

      h1, h2, h3, p, ol, ul {
        text-shadow: none;
        text-align: left;
        color: #333;
      }

      h1, h2, h3 {
        color: var(--titleColor);
      }
    }

    .modal__image-box,
    .modal__task-points {
      display: none;
      visibility: hidden;
    }

    .modal__close {
      top: -8px;
      right: -20px;
      position: fixed;
      opacity: 0;
      animation: helpCloseIn .5s forwards;
      animation-delay: .5s;

      background: url("../modal/images/cross-bg.png") no-repeat;
      background-size: 100%;
      width: 108px;
      height: 106px;
      font-size: 0;
      //z-index: 5;

      @include large-desktop-view() {
        top: 24px;
        right: calc((100% - 984px) / 2);
      }

      span {
        cursor: pointer;
        position: absolute;
        content: '';
        background: url("../modal/images/cross.png") 50% no-repeat;
        width: 36px;
        height: 40px;
        background-size: 100%;
        top: 23px;
        left: 36px;
        transition: opacity .2s;

        &:hover {
          opacity: .8;
        }
      }
    }
  }

  &_with-swiper {
    .modal__content {
      padding: 0;
      background: none;
      box-shadow: none;
    }

    .modal__body {
      //padding: 60px 50px;
      //overflow: hidden;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--lightColor);
        box-shadow: 0 24px 80px rgba(#000, .6);
      }
    }

    .modal__close {
      top: -21px;
    }

    .modal__corner_lt {
      top: 11px;
    }

    .modal__corner_bl, .modal__corner_br {
      bottom: -14px;
    }

  }

  &__image-box {
    margin: -30px -20px 32px;

    @include small-desktop-view() {
      margin: -60px -50px 32px;

    }
  }

  &__image {
    display: block;
    max-width: 100%;

    &_disabled {
      display: none;
    }
  }

  &__real-image {
    display: block;
    max-width: 100%;

    &_disabled {
      display: none;
    }
  }

  &__close {
    background: url("./images/cross-bg.png") no-repeat;
    background-size: 100%;
    position: absolute;
    width: 108px;
    height: 106px;
    right: -46px;
    font-size: 0;
    top: -34px;
    z-index: 20;

    span {
      cursor: pointer;
      position: absolute;
      content: '';
      background: url("./images/cross.png") 50% no-repeat;
      width: 36px;
      height: 40px;
      background-size: 100%;
      top: 24px;
      left: 36px;
      transition: opacity .2s;

      @include large-desktop-view() {
        top: 23px;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  &__overlay {
    background: rgba(#1B1B36, .75);
    transition: background-color .2s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &_cart .modal__content,
  &__content {
    max-width: 520px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    z-index: 10000;
    position: relative;
    //top: 50%;
    //position: absolute;
    //transform: translate(-50%, -50%);
    //transform-origin: 0;
    //left: 50%;
    //animation: modalIn .2s ease-in forwards;
    //animation-iteration-count: 1;
    min-height: 100px;
    box-shadow: 0 24px 80px rgba(#000, .6);
    padding: 30px 20px;
    background: linear-gradient(180deg, #00B7FF 0%, #7948F8 100%);

    @include small-desktop-view() {
      width: 100%;
      padding: 60px 50px;
    }
  }

  &__body {
    font-size: 20px;
    line-height: 24px;
    text-shadow: 0 2px 4px var(--shadowDarkColor);
    color: var(--lightColor);

    @include small-desktop-view() {
      font-size: 24px;
      line-height: 29px;
    }

    h2, p {
      text-align: center;
    }

    ul {
      margin: 16px 0 16px 8px;
    }

    h2 {
      font-size: 26px;
      line-height: 32px;
      text-transform: uppercase;
      margin-bottom: 5px;

      @include small-desktop-view() {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 10px;
      }
    }

    a {
      color: var(--titleColor);

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__corner {
    background-size: 100%;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    position: absolute;
    z-index: 5;
    background-image: url("./images/Corner-LT.png");

    &_lt {
      top: -1px;
      left: -1px;
    }

    &_rt {
      transform: scaleX(-1);
      right: -1px;
      top: -1px;
    }

    &_bl {
      transform: scaleY(-1);
      bottom: -1px;
      left: -1px;
    }

    &_br {
      bottom: -1px;
      right: -1px;
      transform: scaleX(-1) scaleY(-1);
    }
  }

  &__actions {
    margin-top: 30px;
    text-align: center;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: -20px;
      background: rgba(#fff, .5);
      opacity: 0;
      transition: opacity .2s;
      visibility: hidden;
      z-index: 20;
    }

    @include small-desktop-view() {
      margin-top: 40px;
    }

    .button {
      display: block;
      margin-left: auto;
      margin-right: auto;

      + .button {
        margin-top: 24px;
      }
    }

    &_disabled {
      &::before {
        opacity: 1;
        visibility: visible;
      }

      .button {
        &::after {
          opacity: 0;
        }
      }
    }
  }

  &__actions-spinner {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__form {
    &_disabled {
      pointer-events: none;

      .text-field, .quiz-options {
        opacity: .8;
      }
    }
  }
}
