@import "src/styles/media";

.quiz-options {
  text-align: left;
  margin-left: 8px;
  color: #333;
  list-style: none;

  li, span, label {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  &__item {
    line-height: 28px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    input {
      display: none;
      visibility: hidden;
      outline: none;

      &:checked + {
        span {
          color: var(--titleColor);

          &::before {
            background-image: url("./images/Radio-active.png");
          }
        }
      }
    }

    span {
      cursor: pointer;
      position: relative;
      padding-left: 32px;
      display: block;
      font-size: 20px;
      line-height: 24px;
      transition: color .2s;
      outline: none;

      @include small-desktop-view() {
        font-size: 24px;
        line-height: 28px;
        padding-left: 40px;
      }

      &:hover {
        &::before {
          //opacity: .8;
        }
      }

      &::before {
        top: 0;
        position: absolute;
        left: 0;
        content: '';
        width: 24px;
        height: 24px;
        background: url("./images/Radio-default.png") 50% no-repeat;
        background-size: 100%;
        border-radius: 8px;
        transition: opacity .2s;

        @include small-desktop-view() {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
