.spinner {
  width: 60px;
  height: 24px;
  background: url("./images/loader-blue.svg") 50% no-repeat;
  background-size: 100%;
  margin: auto;

  &_light {
    background-image: url("./images/loader.svg");
  }

  &_large {
    width: 120px;
    height: 48px;
  }
}
