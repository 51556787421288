@import "src/styles/media";

.button {
  width: 202px;
  height: 64px;
  border: 0;
  background: none;
  background-size: 100%;
  background-position: 50%;
  position: relative;
  font-family: var(--baseFont);
  text-transform: uppercase;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    z-index: 5;
  }

  &::after {
    position: absolute;
    content: '';
    width: 86%;
    height: 40%;
    border-radius: 12px;
    box-shadow: 0 4px 38px rgba(0, 117, 255, 0.8);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all .25s;
  }

  &:hover {
    &::after {
      box-shadow: 0 4px 20px rgba(0, 0, 0, .5);
    }
  }

  &_mod {
    &_basic {
      &::before {
        background-image: url("./images/Button.png");
      }
      .button__text {
        //background-image: url("./images/Button.png");
      }
    }

    &_danger {
      .button__text {
        background-image: url("./images/Button-second.png");
      }
    }
  }

  &_responsive {
    width: 120px;
    height: 38px;

    .button__text {
      font-size: 16px;
      line-height: 38px;
    }

    &:hover {
      &::after {
        box-shadow: 0 0 38px rgba(#a2ceff, .9);
      }
    }

    @include small-desktop-view() {
      width: 202px;
      height: 64px;

      .button__text {
        font-size: 24px;
        line-height: 64px;
      }
    }
  }

  &__text {
    position: absolute;
    z-index: 10;
    transition: all 0.25s;
    font-size: 24px;
    color: var(--lightColor);
    line-height: 64px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    //text-shadow: 0 1px 2px var(--shadowColor);
    text-shadow: 0 1px 2px rgba(36, 0, 255, 0.2), 0 1px 4px rgba(36, 0, 255, 0.5);
    background-size: 100%;
  }

  &:disabled {
    pointer-events: none;

    .button__text {
      opacity: .4;
    }
  }

}
