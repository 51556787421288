.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 128px;
  height: 128px;
  background: url("./images/User.png") 50% no-repeat;
  background-size: 100%;
  z-index: 25;

  &__image-box {
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__image {
    display: block;
    max-width: 100%;
  }

  &__circle {
    width: 128px;
    height: 128px;
    z-index: 1;
    opacity: 0;
  }
}
