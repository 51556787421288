@import "src/styles/media";

.uncompleted-tasks-modal {

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 28px;
    height: 64px;
    z-index: 20;
    cursor: pointer;
    background: url("./images/prev.svg") 50% no-repeat;
    background-size: 70%;
    transition: opacity .2s;
    font-size: 0;
    top: 50%;

    @include for-modal-view() {
      background-size: 100%;
      top: 50%;
    }
  }

  .swiper-button-prev {
    left: -28px;

    @include for-modal-view() {
      left: -54px;
    }
  }

  .swiper-button-next {
    right: -28px;
    background-image: url("./images/next.svg");

    @include for-modal-view() {
      right: -54px;
    }
  }

  .swiper-button-disabled {
    opacity: 0.5;
  }
}

.swiper {
  //overflow: visible;
  padding: 13px 0 0;
}

.uncompleted-tasks {
  &__item {
    padding: 30px 20px;

    @include small-desktop-view() {
      padding: 60px 50px;
    }
  }
}
