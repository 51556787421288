:root {
  --width: 35px;
  --height: 35px;
  --edgeSize: 11px;
  --translateZ: 17px;
  --translateZNeg: -17px;
  --dotSize: 8pt;
  --faceBg: rgba(255, 255, 255, 1);
  --borderClr: rgba(0, 0, 0, 0.2);
  --dotColor: rgba(0, 0, 0, 0.7);
  --borderRadius: 2px;
}

$width: 35px;

.dices {
  display: flex;
  justify-content: space-evenly;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  max-width: 160px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &__display {
    width: var(--width);
    height: var(--height);
    perspective: 450px;
    margin: 8px;

    &:nth-child(1) {
      position: absolute;
      left: 20px;
      top: -35px;
    }

    &:nth-child(2) {
      position: absolute;
      right: 15px;
      top: -20px;
    }
  }
}

.dice {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(var(--translateZNeg));
  transition: transform 0.4s;

  &__face {
    position: absolute;
    width: var(--width);
    height: var(--height);
    display: grid;
    grid-template-columns: var(--edgeSize) var(--edgeSize) var(--edgeSize);
    grid-template-rows: var(--edgeSize) var(--edgeSize) var(--edgeSize);
    border: 1px solid var(--borderClr);
    color: var(--dotColor);
    border-radius: var(--borderRadius);
    background-image: url("./images/dice-faces.png");
    background-repeat: no-repeat;
    background-size: var(--width);
    background-position: 0 0 ;
    font-size: 0;
  }
}


/* 3 */
.face-front {
  background-position: 0 -#{$width * 2};
  transform: rotateY(0deg) translateZ(var(--translateZ));
}

/* 4 */
.face-back {
  background-position: 0 -#{$width * 3};
  transform: rotateY(180deg) translateZ(var(--translateZ));
}

/* 5 */
.face-right {
  background-position: 0 -#{$width * 4};
  transform: rotateY(90deg) translateZ(var(--translateZ));
}

/* 2 */
.face-left {
  background-position: 0 -#{$width};
  transform: rotateY(-90deg) translateZ(var(--translateZ));
}

/* 1 */
.face-top {
  background-position: 0 0;
  transform: rotateX(90deg) translateZ(var(--translateZ));
}

/* 6 */
.face-bottom {
  background-position: 0 -#{$width * 5};
  transform: rotateX(-90deg) translateZ(var(--translateZ));
}

.idle {
  animation: idle linear infinite 6s;
}


@keyframes idle {
  from {
    transform: translateZ(var(--translateZNeg)) rotateX(0deg) rotateY(0deg);
  }

  to {
    transform: translateZ(var(--translateZNeg)) rotateX(360deg) rotateY(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    -webkit-transform: translateZ(var(--translateZNeg)) scale(1) rotate3d(-1, 1, 0, 0deg);
    transform: translateZ(var(--translateZNeg)) scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: translateZ(var(--translateZNeg)) scale(1.4) rotate3d(-1, 1, 0, 180deg);
    transform: translateZ(var(--translateZNeg)) scale(1.4) rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: translateZ(var(--translateZNeg)) scale(1) rotate3d(-1, 1, 0, 360deg);
    transform: translateZ(var(--translateZNeg)) scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}

.show-top {
  transform: translateZ(var(--translateZNeg)) rotateX(-90deg);
}

.show-front {
  transform: translateZ(var(--translateZNeg)) rotateY(0deg);
}

.show-right {
  transform: translateZ(var(--translateZNeg)) rotateY(-90deg);
}

.show-back {
  transform: translateZ(var(--translateZNeg)) rotateY(-180deg);
}

.show-left {
  transform: translateZ(var(--translateZNeg)) rotateY(90deg);
}

.show-top {
  transform: translateZ(var(--translateZNeg)) rotateX(-90deg);
}

.show-bottom {
  transform: translateZ(var(--translateZNeg)) rotateX(90deg);
}
//
//.show-top {
//  transform: translateZ(var(--translateZNeg)) rotateX(-85deg);
//}
//
//.show-front {
//  transform: translateZ(var(--translateZNeg)) rotateY(5deg);
//}
//
//.show-right {
//  transform: translateZ(var(--translateZNeg)) rotateY(-85deg);
//}
//
//.show-back {
//  transform: translateZ(var(--translateZNeg)) rotateY(-175deg);
//}
//
//.show-left {
//  transform: translateZ(var(--translateZNeg)) rotateY(85deg);
//}
//
//.show-top {
//  transform: translateZ(var(--translateZNeg)) rotateX(-85deg);
//}
//
//.show-bottom {
//  transform: translateZ(var(--translateZNeg)) rotateX(85deg);
//}
