@import "src/styles/media";

.top-bar {
  position: relative;
  user-select: none;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 180px;
    background: linear-gradient(
            180deg,
            rgba(0, 100, 255, .8) 0%,
            rgba(8, 100, 255, 0) 100%
    );
    pointer-events: none;
  }

  &__logo {
    width: 200px;
    height: 121px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 16px;

    @include small-desktop-view() {
    }

    @include large-desktop-view() {
      top: 0;
      width: 380px;
      height: 230px;
    }

    &_game {
      background-image: url("./images/Logo.png");
    }

    &_market {
      background-image: url("./images/Logo-market.png");
    }
  }

  &__profile {
    display: none;
    visibility: hidden;

    @include small-desktop-view() {
      display: block;
      visibility: visible;
      width: 320px;
      height: 160px;
      background: url("./images/Topbar-L.png") no-repeat;
      background-size: 100%;
    }

    @include large-desktop-view() {
      width: 478px;
      height: 240px;
    }
  }

  &__info {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .coins {
      position: absolute;
      right: -15px;
      top: -15px;
      height: 150px;
      z-index: 15;

      @include small-desktop-view() {
        right: 140px;
        top: -16px;
      }

      @include large-desktop-view() {
        left: -189px;
        right: 0;
        top: 16px;
      }
    }

    @include small-desktop-view() {
      left: auto;
      background: url("./images/Topbar-R.png") no-repeat;
      background-size: 100%;
      width: 320px;
      height: 160px;
    }

    @include large-desktop-view() {
      width: 564px;
      height: 240px;
    }
  }

  &__balance {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 62px;
    text-align: center;
    user-select: none;
    color: #72e9ff;

    @include small-desktop-view() {
      transform: none;
      text-align: right;
      color: var(--lightColor);
      text-shadow: 0 1px 2px var(--shadowColor);
      font-size: 24px;
      line-height: 28px;
      top: 9px;
      left: auto;
      right: 100%;
      padding-right: 42px;
      width: 100px;
    }

    @include large-desktop-view() {
      font-size: 38px;
      line-height: 46px;
      left: auto;
      top: 11px;
      right: 100%;
      padding-right: 84px;
      width: 160px;
    }
  }

  &__help-button,
  &__lang-button {
    background: url("./images/faq-mobile.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    transition: opacity 0.1s;
    opacity: 1;
    top: 33px;
    left: 10px;
    color: #72e9ff;

    span {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 1px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 62px;
      text-transform: uppercase;

      @include small-desktop-view() {
        display: none;
        visibility: hidden;
      }
    }

    @include small-desktop-view() {
      background: url("./images/faq.png") no-repeat;
      background-size: 100%;
      left: auto;
      font-size: 0;
      width: 34px;
      height: 34px;
      right: 92px;
      top: 38px;
    }

    @include large-desktop-view() {
      width: 52px;
      height: 52px;
      right: 166px;
      top: 72px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__lang-button {
    display: none;
    visibility: hidden;

    @include small-desktop-view() {
      display: block;
      visibility: visible;
      right: 42px;
    }

    @include large-desktop-view() {
      right: 78px;
    }


    &_ru {
      background-image: url("./images/Flag_Russia_2x.png");
    }

    &_en {
      background-image: url("./images/Flag_USA_2x.png");
    }
  }

  &__market-button,
  &__game-button {
    background: url("./images/market-mobile.png") 50% no-repeat;
    background-size: 100%;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 16px;
    top: 33px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.1s;

    font-size: 38px;
    line-height: 46px;
    letter-spacing: 1px;
    color: var(--lightColor);

    @include small-desktop-view() {
      background: url("./images/market.png") 50% no-repeat;
      background-size: 100%;
      width: 48px;
      height: 48px;
      top: 31px;
      right: 143px;
    }

    @include large-desktop-view() {
      width: 72px;
      height: 70px;
      right: 258px;
      top: 62px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__game-button {
    background-image: url("./images/game.png");
  }
}

.profile {
  position: relative;

  &__logout {
    background: url("./images/logout.png") 50% no-repeat;
    background-size: 100%;
    position: absolute;
    display: block;

    font-size: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.1s;

    @include small-desktop-view() {
      width: 48px;
      height: 48px;
      top: 41px;
      left: 39px;
    }

    @include large-desktop-view() {
      width: 72px;
      height: 72px;
      top: 62px;
      left: 58px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__name {
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    color: var(--lightColor);
    text-shadow: 0 1px 2px var(--shadowColor);
    letter-spacing: 1px;

    @include small-desktop-view() {
      top: 52px;
      left: 104px;
      font-size: 22px;
      line-height: 24px;
      width: 140px;
    }

    @include large-desktop-view() {
      top: 79px;
      left: 167px;
      font-size: 30px;
      line-height: 36px;
      width: 210px;
    }
  }
}
