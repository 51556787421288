$mobileBig: 375px;
$mobileMax: 767px;
$tabletMin: 768px;
$tabletMax: 1024px;
$desktopMin: 1140px;
$desktopLarge: 1600px;

$retina: (192dpi);

@mixin desktop-view {
  @media (min-width: $desktopMin) {
    @content;
  }
}

@mixin small-desktop-view {
  @media (min-width: $tabletMin) {
    @content;
  }
}

@mixin large-desktop-view {
  @media (min-width: $desktopMin) {
    @content;
  }
}

@mixin less-desktop-view {
  @media (max-width: $tabletMax) {
    @content;
  }
}

@mixin tablet-view {
  @media (min-width: $tabletMin) and (max-width: $tabletMax) {
    @content;
  }
}

@mixin greater-mobile-view {
  @media (min-width: $tabletMin) {
    @content;
  }
}

@mixin greater-small-mobile-view {
  @media (min-width: $mobileBig) {
    @content;
  }
}

@mixin mobile-view {
  @media (max-width: $mobileMax) {
    @content;
  }
}

@mixin for-modal-view {
  @media (min-width: $mobileMax) {
    @content;
  }
}

@mixin retina-view {
  @media (min-resolution: $retina),
  (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin media($expression, $media_type: "only screen") {
  @media #{$media_type} and #{$expression} {
    @content;
  }
}

@mixin media-retina($expression: "") {
  // duplication of improper sorting code
  @if $expression != "" {
    $expression: "" +
      "#{$expression} and (min-device-pixel-ratio: 2), "+"#{$expression} and (min-resolution: 192dpi), "+
      "#{$expression} and (min-resolution: 2dppx) ";

    @include media($expression: $expression) {
      @content;
    }
  } @else {
    $expression: "" +
      "#{$expression}(min-device-pixel-ratio: 2), "+"#{$expression}(min-resolution: 192dpi), "+
      "#{$expression}(min-resolution: 2dppx) ";

    @include media($expression: $expression) {
      @content;
    }
  }
}
