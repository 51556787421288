.map {
  &__atlanta-image {
    position: absolute;
    top: 1170px;
    left: 3007px;
    height: 595px;
    z-index: 30;

  }

  &__saratov-image {
    position: absolute;
    top: 1204px;
    left: 1159px;
    height: 347px;
    z-index: 30;

  }

  &__arm-image {
    position: absolute;
    top: 885px;
    left: 3516px;
    height: 230px;
    z-index: 30;
  }

  &__finish {
    position: absolute;
    top: 2235px;
    left: 4331px;
    height: 195px;
    z-index: 30;
  }

  &__mask-1 {
    z-index: 30;
    position: absolute;
    top: 468px;
    left: 3710px;
    transform: scale(0.5);
  }

  &__mask-6 {
    z-index: 30;
    position: absolute;
    top: 2064px;
    left: 4168px;
    transform: scale(0.5);
  }
}

.position-container {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  height: 120px;
  width: 120px;

  &__image-container {
    position: absolute;
    min-height: 42px;
    min-width: 42px;
    border-radius: 50%;
    display: inline-block;
    background-image: url('./images/user-friend.png');
    background-size: 42px;
    background-repeat: no-repeat;

    &:not(:first-child) {
      margin: 0 0 0 0;
    }
  }

  &__user-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 28px;
    width: 28px;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    &.disabled {
      visibility: hidden;
      display: none;
    }

  }

  &__users-number {
    color: white;
    margin: 0 0 0 5px;
    display: inline-block;
  }
}
