* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #01114d;
}

:root {
  --baseFont: "DIN-Condensed";
  --shadowColor: #2400ff;
  --shadowDarkColor: rgba(0, 0, 0, 0.25);
  --lightColor: #fff;
  --textFieldBg: #e8f3ff;
  --textFieldBorder: #a2ceff;
  --titleColor: #407cfb;

  --spinner-size: 24px;
  --clr-spinner1: #58a0eb;
  --clr-spinner2: #3b88d9;
  --clr-spinner3: #0e68c5;
  --spinner-animation-duration: 1.75s;
}

@font-face {
  font-family: "DIN-Condensed";
  src: url("../fonts/din_condensed_bold-webfont.woff") format("woff2"),
    url("../fonts/din_condensed_bold-webfont.woff2") format("woff");
}

.document {
  margin: 0;

  &__body {
    font-family: var(--baseFont);
    -webkit-font-smoothing: antialiased;
    min-width: 320px;
    overflow: hidden;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../components/map/images/map.jpg") 50% no-repeat;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.5);
    backdrop-filter: blur(20px);
  }

  img {
    display: block;
    max-width: 100%;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
