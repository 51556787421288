@import "src/styles/media";

.bottom-bar {
  background: url("./images/Bottom-Bar.png") no-repeat;
  background-size: 100%;
  width: 320px;
  height: 152px;
  margin: 0 auto 24px;
  position: relative;
  pointer-events: auto;

  @include greater-small-mobile-view() {
    width: 375px;
    height: 179px;
  }

  @include small-desktop-view() {
    width: 587px;
    height: 280px;
    margin-bottom: 40px;
  }

  &__dices {
    width: 136px;
    height: 136px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.65);
    z-index: 5;
    pointer-events: none;

    @include small-desktop-view() {
      transform: translate(-50%, -50%);
    }

    &.hidden {
      display: none;
      visibility: hidden;
    }

    button {
      position: absolute;
      top: -40px;
    }
  }

  &__roll-btn {
    background: url("./images/Button-Drop.png");
    background-size: 100%;
    width: 72px;
    height: 72px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: all .1s;

    span {
      color: var(--lightColor);
      text-shadow: 0 2px 2px rgb(0 0 0 / 25%);
      text-transform: uppercase;
      position: absolute;
      text-align: center;
      letter-spacing: 0.055em;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      line-height: 18px;
      font-size: 14px;
      top: 23px;

      @include greater-small-mobile-view() {
        width: 83px;
        line-height: 22px;
        font-size: 18px;
        top: 27px;
      }

      @include small-desktop-view() {
        width: 83px;
        line-height: 26px;
        font-size: 25px;
        top: 43px;
      }
    }

    @include greater-small-mobile-view() {
      width: 88px;
      height: 88px;
    }

    @include small-desktop-view() {
      width: 136px;
      height: 136px;
    }

    &.pressed {
      transform: translate(-50%, -50%) scale(.95);
      transition: all .3s;
    }

    &.roll {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__dices-summary,
  &__throw-count {
    font-size: 18px;
    line-height: 1;
    color: var(--lightColor);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 32px;
    text-align: center;
    top: 68px;

    @include greater-small-mobile-view() {
      font-size: 22px;
      top: 78px;
    }

    @include small-desktop-view() {
      top: 124px;
      font-size: 30px;
      line-height: 36px;
      width: 32px;

    }
  }

  &__dices-summary {
    right: 92px;

    @include greater-small-mobile-view() {
      right: 111px;
    }

    @include small-desktop-view() {
      right: 182px;
    }
  }

  &__dices-summary-text {
    position: absolute;

    color: var(--lightColor);
    text-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    text-transform: uppercase;
    width: 100px;
    letter-spacing: .1em;
    line-height: 15px;
    font-size: 10px;
    right: -10px;
    top: 62px;

    @include greater-small-mobile-view() {
      line-height: 17px;
      font-size: 12px;
      right: 6px;
      top: 72px;
    }

    @include small-desktop-view() {
      right: 58px;
      top: 121px;
      line-height: 19px;
      font-size: 15px;
    }

    &.en {
      line-height: 30px;
      @include greater-small-mobile-view() {
        line-height: 38px;
      }
    }
  }

  &__throw-count-text {
    color: var(--lightColor);
    text-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    text-transform: uppercase;
    width: 80px;
    letter-spacing: .1em;
    position: absolute;
    text-align: right;
    font-size: 10px;
    line-height: 15px;
    top: 62px;
    left: 10px;

    &.en {
      width: 70px;
      left: 20px;
      @include greater-small-mobile-view() {
        width: 80px;
      }
      @include small-desktop-view() {
        width: 100px;
        left: 62px;
      }
    }

    @include greater-small-mobile-view() {
      width: 100px;
      font-size: 12px;
      line-height: 17px;
      top: 72px;
      left: 6px;
    }

    @include small-desktop-view() {
      left: 60px;
      letter-spacing: .1em;
      font-size: 15px;
      position: absolute;
      top: 121px;
    }
  }

  &__throw-count {
    left: 92px;

    @include greater-small-mobile-view() {
      left: 111px;
    }

    @include small-desktop-view() {
      left: 182px;
    }
  }

  &__uncompleted-tasks {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    bottom: -14px;

    @include small-desktop-view() {
      bottom: -16px;
    }
  }

  &__double-label {
    width: 96px;
    height: 76px;
    position: absolute;
    left: 50%;
    top: -34px;
    transform: translateX(-50%);

    @include small-desktop-view() {
      left: 150px;
      top: 42px;
      transform: unset;
    }
  }
}
