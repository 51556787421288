.app {
  display: flex;
  position: relative;
}

.appTopBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.appBottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 180px;

    background: linear-gradient(to top,
        rgba(0, 100, 255, .8) 0%,
        rgba(8, 100, 255, 0) 100%
    );
  }
}

.gameBoard {
  position: relative;

  .map {
    position: absolute;
    left: 0;
    top: 0;
  }
}
