@import "src/styles/media";

.help-modal {
  font-size: 18px;
  line-height: 24px;

  @include small-desktop-view() {
    font-size: 24px;
    line-height: 32px;
  }

  h1, h2, h3 {
    text-transform: uppercase;
    line-height: 1;
    color: var(--titleColor);

  }

  h1 {
    font-size: 40px;
    margin: 0 0 32px;

    @include small-desktop-view() {
      font-size: 48px;
    }
  }

  h2 {
    margin: 56px 0 16px;
  }

  h3 {
    margin: 16px 0 8px;
  }

  p {
    margin: 8px 0;
  }

  ol {
    margin: 8px 0 8px 16px;

    li {
      margin: 8px 0;
    }
  }

  &__footer {
    text-align: center;

    h3 {
      //text-align: center!important;
    }

    .button {
      margin-top: 32px;
    }
  }
}
