@import "src/styles/media";

.text-field {
  position: relative;
  margin: 20px 0 0;

  @include small-desktop-view() {
    margin: 40px 0 0;
  }

  &::after {
    position: absolute;
    content: '';
    width: 70px;
    height: 13px;
    background: url("./images/input.svg") 50% no-repeat;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__input {
    background: var(--textFieldBg);
    border: 2px solid var(--textFieldBorder);
    outline: none;
    height: 56px;
    line-height: 56px;
    padding: 0 16px;
    display: block;
    width: 100%;
    border-radius: 4px;
    font-size: 24px;
    color: #333;
    transition: all .25s;
    font-family: var(--baseFont);

    &:hover,
    &:focus {
      border-color: darken(#A2CEFF, 16);
    }

    &:focus {
      background: #fff;
      box-shadow: 0 4px 16px rgba(darken(#A2CEFF, 8), .5);
    }
  }
}
